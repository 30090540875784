import React from 'react'

import BackgroundImage from 'gatsby-background-image'

import { graphql, navigate, useStaticQuery } from 'gatsby'
import { WelcomeMedicoQuery } from '../generated/graphql'

import styled from '@emotion/styled'
import { useCookies } from 'react-cookie'
import WelcomeHeader from './WelcomeHeader'
import WelcomeGrid from './WelcomeGrid'
import WelcomeFooter from './WelcomeFooter'
import WelcomeBody from './WelcomeBody'
import { Link } from '@chakra-ui/core'

const StyledGBI = styled(BackgroundImage)`
	width: 100vw;
	height: 100%;
	max-width: 100%;

	@media (max-width: 640px) {
		/* background-size: auto; */
		/* see: https://github.com/timhagn/gatsby-background-image/tree/master/packages/gatsby-background-image#styling--passed-through-styles */
		background-position: -800px !important;

		&::before,
		&::after {
			/* background-size: auto; */
			/* see: https://github.com/timhagn/gatsby-background-image/tree/master/packages/gatsby-background-image#styling--passed-through-styles */
			background-position: -800px !important;
		}
	}
`

export const query = graphql`
	query WelcomeMedico {
		file(relativePath: { eq: "welcome-medico.jpg" }) {
			childImageSharp {
				fluid(quality: 100, maxWidth: 1920) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
	}
`

const WelcomeMedico = () => {
	const data: WelcomeMedicoQuery = useStaticQuery(query)
	const [cookies, setCookie, removeCookie] = useCookies()

	const onSubmit = (values: { dontShow: boolean }) => {
		if (values.dontShow) {
			setCookie('medico-welcome', true, {
				maxAge: 31536000 // 1 year
			})
		}
		navigate('/assinatura?tipo=medico&nexodata=0&ezorder=0')
	}

	return (
		<WelcomeGrid>
			<WelcomeHeader />
			<StyledGBI Tag='section' fluid={data.file?.childImageSharp?.fluid}>
				<WelcomeBody
					onSubmit={onSubmit}
					title='Criado para facilitar a prescrição eletrônica: receita médica, com você.'
					text={
						<>
							Assine digitalmente com o{' '}
							<Link
								textDecoration='none'
								color='brand.500'
								href='https://vidaas.com.br'
								target='_blank'
								rel='noopener'
								_hover={{
									textDecoration: 'none',
									color: 'brand.400'
								}}
							>
								VIDaaS: Certificado Digital em Nuvem Valid
							</Link>{' '}
							e envie receitas aos seus pacientes de forma rápida, segura e controlada como estabelece as normas do
							Conselho Federal de Medicina e o Governo Brasileiro.
							<br />
							<br />
							Você poderá gerenciar os receituários prescritos com receita simples, especiais, antimicrobianas e
							atestados individuais em um só lugar, gratuitamente.
						</>
					}
				/>
			</StyledGBI>
			<WelcomeFooter />
		</WelcomeGrid>
	)
}

export default WelcomeMedico
