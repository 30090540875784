import React from 'react'
import WelcomeMedico from '../Welcome/WelcomeMedico'
import Layout from '../components/layout'
import SEO from '../components/seo'

const WelcomeMedicoPage = () => {
	return (
		<Layout>
			<SEO keywords={[`valid`, `receita`, `médica`, `médico`]} title='Boas vindas' />
			<WelcomeMedico />
		</Layout>
	)
}

export default WelcomeMedicoPage
